import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const SiteTitle = () => 
    <Title>
      <Link to={process.env.REACT_APP_WEBSITE} style={{color:'inherit', margin: 0}}>
        <Logo src="/android-icon-192x192.png"/>
        <TitleWrapper>
          <Bold>{ process.env.REACT_APP_NAME }</Bold> Live <Bold>Video</Bold> Commerce
        </TitleWrapper>
      </Link>
    </Title>    

const Title = styled.div`
  text-transform: uppercase;
  font-weight: 300;
`;

const Bold = styled.span`
  font-weight: 500;
`;

const Logo = styled.img`
  width: 100%;
  max-width: 42px;
  margin-right: 5px;
  vertical-align: middle;
`;

const TitleWrapper = styled.span`
  display: none;
  @media (min-width: 769px) {
    display: inline-block;
  }
`