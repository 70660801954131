import React, {createContext, useReducer, useEffect} from 'react';
import {initializeApp, getApp, getApps} from 'firebase/app';
import {getAuth, onAuthStateChanged, User, Auth} from 'firebase/auth';
import * as firestore from 'firebase/firestore';
import 'firebase/database';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {reducer} from 'lib';
import {Loading, GenericObject} from '@kwixl/elements';
import {
  getStorage,
  FirebaseStorage,
} from 'firebase/storage';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

//firestore.setLogLevel('debug');

const app = initializeApp(config);

const firestoreSettings: firestore.FirestoreSettings & {
  useFetchStreams: boolean;
} = {
  useFetchStreams: false,
};

const db = firestore.initializeFirestore(app, firestoreSettings);

const auth = getAuth(app);

const functions = getFunctions(app);

const initialState = {
  firebaseUser: null,
  organization: null,
  userProfile: null,
  userCanSell: false,
  userHome: '/me',
  loginComplete: false,
  merchant: {
    status: 'none',
    card: null,
    processor: null,
  },
};

export interface FirebaseContextProps {
  firebaseUser: User;
  organization: GenericObject;
  userProfile: GenericObject;
  userCanSell: boolean;
  userHome: string;
  loginComplete: boolean;
  merchant: {
    status: string;
    card: boolean;
    processor: string;
  };
  callable: (name: string, data?: GenericObject) => GenericObject;
  userFullName: () => {};
  setFirebaseUser: (user) => void;
  checkMerchantAccountAndBilling: () => Promise<GenericObject>;
  updateCollectionArray: (
    snap: firestore.QuerySnapshot,
    items: any[],
    callback?: (data: GenericObject[]) => void,
    transformer?: (data: GenericObject) => void,
    comparator?: (item: GenericObject, snap) => void
  ) => void;
  db: firestore.Firestore;
  auth: Auth;
  // refs
  addressesRef: firestore.CollectionReference;
  addressRef: (id: string) => firestore.DocumentReference;
  applicationsRef: firestore.CollectionReference;
  applicationRef: (id: string) => firestore.DocumentReference;
  eventsRef: firestore.CollectionReference;
  eventRef: (id: string) => firestore.DocumentReference;
  eventPrivateRef: (eventId: string) => firestore.DocumentReference;
  videoSessionsRef: (eventId: string) => firestore.CollectionReference;
  videoSessionRef: (eventId: string, id: string) => firestore.DocumentReference;
  eventAttendeesRef: (eventId: string) => firestore.CollectionReference;
  eventFacebookChatUsersRef: (eventId: string) => firestore.CollectionReference;
  eventsIndexRef: firestore.CollectionReference;
  eventIndexRef: (eventId: string) => firestore.DocumentReference;
  purchasesRef: firestore.CollectionReference;
  publicEventsIndexRef: firestore.CollectionReference;
  categoriesRef: firestore.CollectionReference;
  merchantAccountsRef: firestore.CollectionReference;
  merchantAccountRef: (id: string) => firestore.DocumentReference;
  rootCategoryRef: firestore.DocumentReference;
  organizationsRef: firestore.CollectionReference;
  organizationRef: (id: string) => firestore.DocumentReference;
  userProfilesRef: firestore.CollectionReference;
  userProfileRef: (id: string) => firestore.DocumentReference;
  getCdn: () => FirebaseStorage;
}

export const FirebaseContext = createContext<Partial<FirebaseContextProps>>({});

export const FirebaseProvider = ({children}) => {
  const [
    {
      firebaseUser,
      organization,
      userProfile,
      userCanSell,
      userHome,
      loginComplete,
      merchant,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const addressesRef = firestore.collection(db, 'addresses');
  const addressRef = (id: string) => firestore.doc(addressesRef, id);
  const applicationsRef = firestore.collection(db, 'applications');
  const applicationRef = (id: string) => firestore.doc(applicationsRef, id);
  const eventsRef = firestore.collection(db, 'events');
  const eventRef = (id: string) => firestore.doc(eventsRef, id);
  const eventPrivateRef = (eventId: string) =>
    firestore.doc(eventRef(eventId), 'private');
  const videoSessionsRef = (eventId: string) =>
    firestore.collection(eventRef(eventId), 'video_sessions');
  const videoSessionRef = (eventId: string, id: string) =>
    firestore.doc(videoSessionsRef(eventId), id);
  const eventAttendeesRef = (eventId: string) =>
    firestore.collection(eventRef(eventId), 'attendees');
  const eventFacebookChatUsersRef = (eventId: string) =>
    firestore.collection(eventRef(eventId), 'facebook_chat_users');
  const eventsIndexRef = firestore.collection(db, 'event_index');
  const eventIndexRef = (eventId: string) =>
    firestore.doc(eventsIndexRef, eventId);
  const merchantAccountsRef = firestore.collection(db, 'merchant_accounts');
  const merchantAccountRef = (id: string) => firestore.doc(merchantAccountsRef, id);
  const purchasesRef = firestore.collection(db, 'purchases');
  const publicEventsIndexRef = firestore.collection(db, 'public_event_index');
  const categoriesRef = firestore.collection(db, 'categories');
  const rootCategoryRef = firestore.doc(categoriesRef, 'root');
  const organizationsRef = firestore.collection(db, 'organizations');
  const organizationRef = (id: string) => firestore.doc(organizationsRef, id);
  const userProfilesRef = firestore.collection(db, 'users');
  const userProfileRef = (id: string) => firestore.doc(userProfilesRef, id);

  let unsubUser = () => {};
  let unsubOrg = () => {};
  let unsubOrgPrivate = () => {};

  useEffect(() => {
    (async () => {
      onAuthStateChanged(auth, async user => {
        dispatch({firebaseUser: user});
      });
    })();
    return () => {
      unsubUser();
      unsubOrg();
      unsubOrgPrivate();
    };
  }, []);

  useEffect(() => {
    if (!userProfile?.orgId) return;
    const {doc, onSnapshot} = firestore;
    const unsubOrg = onSnapshot(
      doc(db, 'organizations', userProfile.orgId),
      snap => {
        dispatch({organization: {...snap.data(), id: snap.id}});
      }
    );
    const unsubOrgPrivate = onSnapshot(
      doc(db, `organizations/${userProfile.orgId}/private/data`),
      snap => {
        if (!snap.exists()) return;
        dispatch({userCanSell: (snap.data() || {})?.permissions?.sell});
      }
    );
    return () => {
      unsubOrg();
      unsubOrgPrivate();
    };
  }, [userProfile]);

  useEffect(() => {
    if (!organization) return;
    checkMerchantAccountAndBilling()
      .then(result => {
        dispatch({
          merchant: result,
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, [organization]);

  useEffect(() => {
    (async () => {
      if (firebaseUser) {
        const {doc, onSnapshot} = firestore;
        // setup initial data and then setup listeners for changes
        unsubUser = onSnapshot(doc(db, 'users', firebaseUser.uid), snap => {
          if (snap.exists()) {
            dispatch({userProfile: {...snap.data(), id: snap.id}});
          }
        });
        dispatch({
          loginComplete: true,
        });
      } else {
        dispatch({loginComplete: true});
      }
      return () => {
        unsubUser();
      };
    })();
  }, [firebaseUser]);

  const getCdn = () => {
    console.log('getting storage for', process.env.REACT_APP_CDN);
    let a = getApp();
    return getStorage(a, `gs://${process.env.REACT_APP_CDN}`);
  }

  const callable = async (name: string, data?: GenericObject) => {
    const func = httpsCallable(functions, name);
    try {
      return await func(data || {});
    } catch (error) {
      console.log(error);
      return {error};
    }
  };

  const userFullName = () => {
    let name = `${userProfile?.firstName} ${userProfile?.lastName}`;
    if (!name.replace(/\s/g, '').trim()) name = firebaseUser?.dispayName || '';
    return name;
  };

  const setFirebaseUser = user => dispatch({firebaseUser: user});

  const checkMerchantAccountAndBilling = async (): Promise<GenericObject> => {
    if (!organization) return {};
    const {getDoc, doc} = firestore;
    const merchantDoc = await getDoc(
      doc(db, 'merchant_accounts', organization.id)
    );
    const {status, card, processor} = merchantDoc.data() || {};
    return {
      status: processor === 'paypal' ? 'active' : status,
      card,
      processor,
    };
  };

  const updateCollectionArray = (
    snap: firestore.QuerySnapshot,
    items = [],
    callback?: (data: GenericObject[]) => void,
    transformer?: (data: GenericObject) => void,
    comparator?: (item: GenericObject, snap) => void
  ) => {
    if (snap.docChanges().length) {
      if (!transformer) {
        transformer = doc => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        };
      }
      const newItems = [...items] || [];
      snap.docChanges().forEach(change => {
        const index = newItems.findIndex(item =>
          comparator ? comparator(item, change) : item?.id === change.doc.id
        );
        const item = transformer(change.doc);
        switch (change.type) {
          case 'removed':
            newItems.splice(index, 1);
            break;
          default:
            if (index >= 0) {
              newItems[index] = item;
            } else {
              newItems.push(item);
            }
        }
      });
      if (callback && typeof callback === 'function') {
        callback(newItems);
      }
    }
  };

  return (
    <FirebaseContext.Provider
      value={{
        //firebase: app,
        db,
        auth,
        callable,
        firebaseUser,
        organization,
        userProfile,
        userCanSell,
        merchant,
        userHome,
        checkMerchantAccountAndBilling,
        updateCollectionArray,
        setFirebaseUser,
        userFullName,
        // refs
        addressesRef,
        addressRef,
        applicationsRef,
        applicationRef,
        eventsRef,
        eventRef,
        eventPrivateRef,
        videoSessionsRef,
        videoSessionRef,
        eventAttendeesRef,
        eventFacebookChatUsersRef,
        eventsIndexRef,
        eventIndexRef,
        merchantAccountsRef,
        merchantAccountRef,
        purchasesRef,
        publicEventsIndexRef,
        categoriesRef,
        rootCategoryRef,
        organizationsRef,
        organizationRef,
        userProfilesRef,
        userProfileRef,
        getCdn,
      }}
    >
      {getApps().length > 0 && (firebaseUser || loginComplete) ? (
        children
      ) : (
        <Loading>Please wait...</Loading>
      )}
    </FirebaseContext.Provider>
  );
};
