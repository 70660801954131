import React, {Component} from 'react';
import {Header, Container, Image} from 'semantic-ui-react';

export class ErrorBoundary extends Component {
  state;

  constructor(props) {
    super(props);
    this.state = {hasError: false, errorMessage: ''};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log(error);
    return {hasError: true, errorMessage: error.message};
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    const {hasError} = this.state;
    if (hasError) {
      return (
        <Container style={{marginTop: '40px'}}>
          <Image src="/android-icon-192x192.png" />
          <Header as="h2">Well...that's an error...</Header>
          <p>
            We're not too sure what just happened, but it's been reported to our
            support staff so we can find out ASAP.
          </p>
          <p>
            In the meantime try <a href="/">going back to the home page</a> and
            trying again.
          </p>
        </Container>
      );
    }
    return <>{this.props.children}</>;
  }
}
