import {Icon, Image} from 'semantic-ui-react';
import {firestore} from '@kwixl/interface';

const VideoProvider = firestore.Event.VideoProvider;
const EventType = firestore.Event.EventType;

export const VideoProviderIcon = ({value, color = true}) => {
  switch (value) {
    case EventType.ccs:
    case VideoProvider.facebook:
      return <Icon name="facebook" color={color ? 'blue' : undefined} />;
    case VideoProvider.youtube:
      return <Icon name="youtube" color={color ? 'red' : undefined} />;
    default:
      return <Image src="/favicon-16x16.png"/>
  }
};
