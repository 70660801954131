import {Routes, Route} from 'react-router-dom';
import {ROUTES} from 'routes';
import {Home} from './Home';
import {Redirect} from 'components';

export const routes = () => (
  <Routes>
    <Route path={ROUTES.home} element={<Home />} />
    <Route path={ROUTES.signup} element={<Redirect to={process.env.REACT_APP_SIGNUP_LINK} />} />
  </Routes>
);
