import React from 'react';
import 'react-app-polyfill/stable';
//import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import App from './App';

//const root = document.getElementById('kwixl-app');

//ReactDOM.render(<App />, root);

const root = ReactDOM.createRoot(
  document.getElementById('kwixl-app') as HTMLElement
);
root.render(
    <App />
);
if (process.env.NODE_ENV === 'development') console.log('ENV', process.env);
