import {useState, createRef} from 'react';
import {Menu, Sidebar, Ref} from 'semantic-ui-react';
import styled from 'styled-components';
import {TopMenu, PageFooter, DebugBar, ContactUs} from 'components';
import {useFirebaseContext} from 'hooks';

export const DefaultLayout = ({
    children = null, 
    fullScreen = false
}) => {

  const {firebaseUser, userProfile} = useFirebaseContext();

  const [sidebarOpened, toggleSidebarOpened] = useState(false);

  const handleSidebarHide = () => toggleSidebarOpened(false);

  const handleToggle = () => toggleSidebarOpened(true);

  let contextRef = createRef<HTMLElement>();

  return (
    <Ref innerRef={contextRef}>
    <PageContainer
      as={Sidebar.Pushable}
      id="desktop-page-container"
    >
      <Sidebar
        size="large"
        as={Menu}
        animation="overlay"
        direction="right"
        onHide={handleSidebarHide}
        vertical
        visible={sidebarOpened}
        inverted
        style={{zIndex: 10000}}
      >
        <Menu.Item header>
          {firebaseUser
            ? `${userProfile?.firstName} ${userProfile?.lastName}`
            : process.env.REACT_APP_NAME}
        </Menu.Item>
        <Menu.Item>
          <ContactUs/>
        </Menu.Item>
      </Sidebar>
      <Sidebar.Pusher
        dimmed={sidebarOpened}
        style={{
          width: '100%',
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <DebugBar/>
        <TopBar id="top-bar" />
        <TopMenu context={contextRef} onMenu={handleToggle} />
        <LayoutContainer id="layout-container" fullScreen={fullScreen}>
          {children}
        </LayoutContainer>
        <PageFooter />
      </Sidebar.Pusher>
    </PageContainer>
    </Ref>
  );
};

const TopBar = styled.div`
  background-color: ${props => props.theme.primaryColor};
  width: 100%;
  height: 10px;
  min-height: 10px;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-context: flex-start;
  align-items: flex-start;
  align-content: stretch;
  height: 100%;
  position: relative;
  margin: 0;
`;

const LayoutContainer = styled.div<{fullScreen: boolean}>`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-context: flex-start;
  align-items: flex-start;
  align-content: stretch;
  padding-top: 20px;
  @media (min-width: 769px) {
      padding-top: 40px;
  }
`;
