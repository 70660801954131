import {ErrorBoundary} from 'components';
import {
  FirebaseProvider,
  SessionProvider,
  SessionContext,
} from 'providers';
import {IntlProvider} from 'react-intl';
import languages from 'intl';
import {PageLoader} from '@kwixl/elements';
import {ThemeProvider} from 'styled-components';
import './App.css';
import {InternalRoutes} from './routes';

const theme = {
  background: {
    color: '#121212',
  },
  primaryColor: '#03a9f4',
  font: {
    primary: {
      color: '#ffffff',
    },
    secondary: {
      color: 'e2e2e2',
    },
    disabled: {
      color: '#888888',
    },
  },
  footer: {
    background: {
      color: '#222222',
    },
  },
  menu: {
    primary: '#fffff',
    secondary: '#2185D0',
    tertiary: '#1B1C1D',
  },
  light: {
    font: {
      primary: {
        color: '#121212',
      },
    },
    background: {
      color: '#ffffff',
    },
  },
  dark: {
    font: {
      primary: {
        color: '#ffffff',
      },
    },
    background: {
      event: 'rgb(27,27,27)',
      color: '#121212',
    },
  },
};

const App = () => (
  <ErrorBoundary>
    <FirebaseProvider>
      <SessionProvider>
        <SessionContext.Consumer>
          {({locale, ready}) => (
              <IntlProvider
                locale={locale}
                defaultLocale="en"
                messages={languages[locale]}
              >
                {ready ? (
                  <>
                    <ThemeProvider theme={theme}>
                      <InternalRoutes/>
                    </ThemeProvider>
                  </>
                ) : (
                  <PageLoader/>
                )}
              </IntlProvider>
          )}
        </SessionContext.Consumer>
      </SessionProvider>
    </FirebaseProvider>
  </ErrorBoundary>
)

export default App;