import React from 'react';
import {Link} from 'react-router-dom';
import {Icon, Header} from 'semantic-ui-react';
import {ROUTES} from 'routes';

export const withBasePage = WrappedComponent => {

  const header = (size = 1, text) => <Header as={`h${size}`}>{text}</Header>;
  const header1 = (text) => header(1, text);
  const header2 = (text) => header(2, text);
  const header3 = (text) => header(3, text);
  const header4 = (text) => header(4, text);
  const header5 = (text) => header(5, text);
  const header6 = (text) => header(6, text);
  const pageTitle = (text) => header2(text);
  
  const returnHeader = (title: any, path?: string) => (
    <Header as="h2">
      <Link to={path || ROUTES.home}>
        <Icon
          name="chevron left"
          color="blue"
          link
          style={{
            display: 'inline',
            marginRight: '8px',
            verticalAlign: 'bottom',
          }}
        />
      </Link>
      {title}
    </Header>
  );

  return class extends React.Component {
    render = () => (
      <WrappedComponent
        header1={header1}
        header2={header2}
        header3={header3}
        header4={header4}
        header5={header5}
        header6={header6}
        pageTitle={pageTitle}
        returnHeader={returnHeader}
      />
    );
  };
};
