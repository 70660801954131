import { Image } from 'semantic-ui-react';
import { withPublicPage } from 'hoc';
import styled from 'styled-components';

export const withSupportPage = WrappedComponent => {

    return withPublicPage(props => {

        const img = (src, size) => <HelpImage size={size} src={`/img/help/${src}`}/>

        const stepHeader = title => <StepHeader>{title}</StepHeader>

        const sectionHeader = title => <SectionHeader>{title}</SectionHeader>
        
        return (
            <SupportContainer>
                <WrappedComponent 
                    { ...props } 
                    img={img}
                    stepHeader={stepHeader}
                    sectionHeader={sectionHeader}
                />
            </SupportContainer>
        )
    })

}

const SupportContainer = styled.div`
    width: 92%;
    max-width: 1600px;
    margin: 0 auto;
    @media (min-width: 769px) {
        width: 85%;
    }
`

const HelpImage = styled(Image)`
    margin-top: 20px;
    margin-bottom: 40px
`

const StepHeader = styled.span`
    display: block;
    font-weight: bolder;
    font-size: 1rem;
    text-decoration: underline;
    margin-bottom: 20px
`

const SectionHeader = styled.span`
    display: block;
    font-weight: bolder;
    font-size: 1rem;
    margin-bottom: 20px
`
