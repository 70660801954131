import {withPublicPage} from 'hoc';
import {useNavigate} from 'react-router-dom';
import {Container, Divider, Card, Icon} from 'semantic-ui-react';
import {AppName} from '@kwixl/elements';
import {ROUTES} from 'routes';

export const Home = withPublicPage(({header1, header2}) => {

    const navigate = useNavigate();

    return (
        <Container>
            {header1(<><AppName/> Support</>)}
            <Divider/>
            <Card fluid onClick={() => navigate(ROUTES.faq)}>
                <Card.Content>
                    {header2(<><Icon name="question"/> Commonly Asked Questions</>)}
                    <Card.Description>
                        Start here!  View commonly asked questions and their answers.
                    </Card.Description>
                </Card.Content>
            </Card>
            <Card fluid onClick={() => navigate(ROUTES.video)}>
                <Card.Content>
                    {header2(<><Icon name="video"/> Video Tutorials</>)}
                    <Card.Description>
                        Watch and learn!  See how to use some of the basic functionality of <AppName/>.
                    </Card.Description>
                </Card.Content>
            </Card>
        </Container>
    );

}, true);
