import {Sticky, Menu, Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {SiteTitle, ContactUs} from 'components';
import {ROUTES} from 'routes';

export const TopMenu = ({context, onMenu}) => {

  if (!context) return null;

  return (
    <div style={{flex: 0, width: '100%'}}>
      <Sticky id="top-menu" context={context} style={{zIndex: '999'}}>
        <StyledMenu id="main-menu" attached="top" secondary pointing borderless>
          <MenuItem>
            <SiteTitle />
          </MenuItem>
          <Menu.Menu position="right" style={{zIndex: 500}}>
            {window.location.pathname !== '/' && (
              <ResponsiveMenuItem>
                <MenuLink to={ROUTES.home}>Home</MenuLink>
              </ResponsiveMenuItem>
            )}
            <MenuItem>
              <Icon
                link
                name="sidebar"
                style={{fontSize: '2rem'}}
                onClick={onMenu}
              />
            </MenuItem>
          </Menu.Menu>
        </StyledMenu>
      </Sticky>
    </div>
  );
};

const StyledMenu = styled(Menu)`
  boxshadow: 0 0 5px 2px rgba(0, 0, 0, 0.33);
  fontsize: 1.5rem;
  padding: 0;
  backgroundcolor: #ffffff;
  color: #000000;
`;

const MenuLink = styled(Link)`
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Open Sans' Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 1px;
  text-transform: uppercase;
  align-self: initial;
`;

const MenuItem = styled(Menu.Item)`
  text-transform: uppercase;
  align-self: initial !important;
  &: .ui.secondary.pointing.menu .item {
    align-self: initial !important;
  }
`;

const ResponsiveMenuItem = styled(MenuItem)`
  display: none !important;
  padding: 0 !important;
  @media (min-width: 769px) {
    display: flex !important;
    padding: 0 1em !important;
  }
`
