import { DefaultLayout } from 'layouts';
import { withBasePage } from 'hoc';

export const withPublicPage = (
    WrappedComponent, 
    fullScreen = false,
) => withBasePage(props => {

    return (
        <DefaultLayout fullScreen={fullScreen}>
            <WrappedComponent
                {...props}
            />
        </DefaultLayout>
    )
});