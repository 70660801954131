import {Grid} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {SiteTitle, ContactUs} from 'components';
import {ROUTES} from 'routes';

export const PageFooter = () => {
  
  return (
    <Footer id="page-footer">
      <FooterContainer>
        <MobileFooter>
            <p>
              &copy; {new Date().getFullYear()} 421, LLC
            </p>
            <p>
              <Link to={ROUTES.faq}>FAQ</Link> 
              {' | '}
              <ContactUs/>
            </p>
            <Version>
              v{process.env.REACT_APP_VERSION}
            </Version>
        </MobileFooter>
        <DesktopFooter>
          <Grid columns={3} widths="equal">
            <Grid.Row>
              <Grid.Column>
                {window.location.pathname !== '/' && (
                  <p>
                    <Link to={ROUTES.home}>
                      Support Home
                    </Link>
                  </p>
                )}
              </Grid.Column>
              <Grid.Column>
                <p>
                  <Link to={ROUTES.faq}>Commonly Asked Questions</Link>
                </p>
              </Grid.Column>
              <Grid.Column>
                <TitleContainer>
                  <SiteTitle />
                </TitleContainer>
                <FooterInfo>
                  <p>
                    &copy; {new Date().getFullYear()} 421, LLC | v{process.env.REACT_APP_VERSION}
                  </p>
                </FooterInfo>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </DesktopFooter>
      </FooterContainer>
    </Footer>
  );
};

const Version = styled.p`
  font-size: .75rem;
  color: #c2c2c2;
`

const MobileFooter = styled.div`
  text-align: center;
  font-size: .9rem;
  margin-top: 10px;
  min-height: 65px;
  @media (min-width: 769px) {
    display: none;
  }
`

const DesktopFooter = styled.div`
  display: none;
  @media (min-width: 769px) {
    display: block;
  }
`

const Footer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 20px !important;
  background-color: ${props => props.theme.footer.background.color} !important;
  padding-bottom: 20px;
  color: ${props => props.theme.font.primary.color};
  & a {
    color: ${props => props.theme.font.primary.color};
  }
  & a:hover {
    text-decoration: underline;
  }
  & a:visited {
    color: ${props => props.theme.font.primary.color};
  }
  align-self: stretch;
  flex: 0;
  margin-top: 40px;
`;

const FooterContainer = styled.div`
  width: 95%;
  margin: 0 auto;
`;

const FooterInfo = styled.div`
  margin-top: 20px;
  font-size: 10px;
  color: ${props => props.theme.font.secondary.color};
`;

const TitleContainer = styled.div`
  font-size: 1.25rem;
`;
