import React from 'react';
import {RouteProps} from 'react-router';
import {BrowserRouter as Router} from 'react-router-dom';
import {ScrollToTop} from 'components';
import {Loading} from '@kwixl/elements';

import {routes as DefaultRoutes} from './Default';

const PublicRoutes = React.lazy(() => import('./Public'));
const VideoRoutes = React.lazy(() => import('./Video'));

export interface PrivateRouteProps extends RouteProps {
  redirect?: string;
}

export const ROUTES = {
  home: '/',
  homeAlt: '/home',
  contact: '/contact',
  signup: '/signup',
  
  // Store
  store: (vanity: string) => `/store/${vanity}`,
  store_go: (vanity: string) => `/store/${vanity}`,

  video: '/video',
  video_create_event: '/video/create-event',
  video_add_inventory: '/video/add-inventory',
  video_live_stream_guide: '/video/livestream-guide',
  video_getting_started: '/video/getting-started',
  faq: '/faq',
  privacy: '/privacy',
  terms: '/terms',
  blocked: '/blocked',
  page404: '/404',
  
};

export const InternalRoutes = () => (
    <Router>
      <ScrollToTop>
        <DefaultRoutes/>
        <React.Suspense fallback={<Loading />}>
          <PublicRoutes />
        </React.Suspense>
        <React.Suspense fallback={<Loading />}>
          <VideoRoutes />
        </React.Suspense>
      </ScrollToTop>
    </Router>
  );


